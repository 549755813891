// src/pages/BookedAppointmentPage.js
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Rate } from "antd";
import { FaStar, FaLongArrowAltRight } from "react-icons/fa";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Select from "../component/Select";
import ImagedoctorSkeletonss from "../component/imageskeleton";
import CONSTANT from "../constant.json";
import axios from "axios";
import { useLocation } from "react-router-dom";
import DoctorsCard from "../component/DoctorsCards";
import ThemeInput from "../component/ThemeInput";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Grid } from "@mui/material";
import AppointmentCalendar from "../component/AppointmentCalendar ";
import { FaRegCheckCircle } from "react-icons/fa";
import "./AppointmentsPage.css";
import { debounce } from "../helper/debouncefunctions"; // Named import
import {
  FormControl,
  InputLabel,
  Select as MuiSelect, // Rename here
  MenuItem,
  Stack,
  OutlinedInput,
  // Checkbox,
  // FormControlLabel,
} from "@mui/material";
import { Helmet } from "react-helmet";

const Appointment = () => {
  const Location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate
  const token = localStorage.getItem("token");
  const [date, setDate] = useState(new Date());
  const [availableSlot, setAvailableSlot] = useState([]);
  const [langPref, setLangPref] = useState([{ name: "English" }]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [selectedLangPref, setSelectedLangPref] = useState("English");
  const [allAvailableDays, setAllAvailableDays] = useState([]);
  // const [labelSlotTime, setLabelSlotTime] = useState("");
  const [labelSlotTime, setLabelSlotTime] = useState({ id: "", timing: "" });
  const [comments, setComments] = useState("");
  const [promo_code, setPromo_Code] = useState("");
  const [whatsapp_no, setWhatsapp_No] = useState("");
  const [doctorApiData, setDoctorApiData] = useState([]);
  const [isVerified, setIsVerified] = useState(false);
  const urlPssarams = new URLSearchParams(Location.search);
  const nameWithId = urlPssarams.keys().next().value;
  const idddd = nameWithId?.split("-").pop();
  const [isChecked, setIsChecked] = useState(false);
  const [isDenied, setIsDenied] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false); // Define isVerifying state
  const [promoResponse, setPromoResponse] = useState(null);
  const [items, setItems] = useState([
    { item_name: "", item_price: "", item_desc: "" },
  ]);
  const [is_multiple_item, setMultipleItems] = useState("1"); // '1' for Yes, '2' for No
  const [doctorType, setDoctorType] = useState("");
  const [serviceOptions, setServiceOptions] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [address, setAddress] = useState("");
  const [contact_no, setNumber] = useState("");

  const FetchWhatsappNumber = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.get(`${CONSTANT.url}api/patient/detail`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (Array.isArray(response.data?.data?.data)) {
        let filter_WhatsappNumber = response.data?.data?.data?.map(
          (e) => e?.phone
        );
        setWhatsapp_No(filter_WhatsappNumber[0]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    FetchWhatsappNumber();
  }, []);

  const totalAmount = cartItems.reduce(
    (total, item) => total + parseFloat(item.itemAmount),
    0
  );

  let discountAmount = 0;
  if (promoResponse) {
    if (promoResponse.discount_type === "Percentage") {
      discountAmount =
        (totalAmount * parseFloat(promoResponse.discount_value)) / 100;
    } else if (promoResponse.discount_type === "Fixed") {
      discountAmount = parseFloat(promoResponse?.discount_value);
    }
  }
  if (discountAmount > totalAmount) {
    discountAmount = totalAmount;
  }
  const finalAmount = totalAmount - discountAmount;
  const addItemToCart = (item, isSelected) => {
    if (isSelected) {
      // console.log("itedsdsdss", item)
      const existingItem = cartItems.find(
        (cartItem) => cartItem.itemName === item.item_name
      );
      if (!existingItem) {
        setCartItems((prevItems) => [
          ...prevItems,
          {
            itemName: item.item_name,
            itemAmount: item.item_price,
            itemId: item.id,
          },
        ]);
      }
    } else {
      // Remove the item from the cart
      setCartItems((prevItems) =>
        prevItems.filter((cartItem) => cartItem.itemName !== item.item_name)
      );
    }
  };

  console.log("spciailidd", nameWithId);

  useEffect(() => {
    if (doctorApiData) {
      const options = [];
      if (doctorApiData.is_clinic === 1) {
        options.push({ id: "clinic", name: "Clinic Visit" });
      }
      if (doctorApiData.is_home === 1) {
        options.push({ id: "home", name: "Home Visit" });
      }
      if (doctorApiData.is_online === 1) {
        options.push({ id: "online", name: "Online Consultation" });
      }
      const uniqueOptions = [
        ...new Map(options.map((item) => [item.name, item])).values(),
      ];
      setServiceOptions(uniqueOptions);
    } else {
      console.warn("doctorApiData is not defined:", doctorApiData);
    }
    console.log("Datatataat", doctorApiData);
  }, [doctorApiData]);

  const formattedDate = new Date(date)
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
  const fetchData = async () => {
    const doctorId = parseInt(idddd, 10); // Convert to number

    try {
      const response = await axios.get(
        `${CONSTANT.url}api/Doctor/get?id=${doctorId}`
      );
      setDoctorApiData(response.data?.data?.[0]);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log("doctorsdataaaa", doctorApiData);
  }, [doctorApiData]);

  useEffect(() => {
    if (doctorApiData || idddd) {
      const doctorId = parseInt(idddd, 10);

      const formattedDate = handleDateFormatter(date);
      fetchAvailableSlots(doctorId, formattedDate);
      fetchAvailableDays(doctorId);
      if (doctorApiData?.name || idddd) {
        // localStorage.setItem("pageTitle", doctorApiData?.name);
      }
    }

    console.log("allllllldoctorssssssssssss", doctorApiData);
  }, [date, doctorApiData, idddd]);

  const handleDateFormatter = (dateToFormat) => {
    const currentDate = new Date(dateToFormat);
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const formatTime = (timeString) => {
    const dateformatTime = new Date(`2000-01-01T${timeString}`);
    return dateformatTime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const fetchAvailableSlots = async (doc_id, date) => {
    try {
      const response = await axios.get(
        `${CONSTANT.url}api/appointments/check/${doc_id}/${date}`
      );
      console.log("API Response:", response);
      if (Array.isArray(response.data?.data)) {
        console.log("Available Slots Data:", response.data.data);
        if (response.data?.data.length) {
          const slotTimeFormat = response.data?.data.map((v) => ({
            ...v,
            timing: `${formatTime(v.start_time)} - ${formatTime(v.end_time)}`,
          }));
          setAvailableSlot(slotTimeFormat);
        } else {
          setAvailableSlot([]);
        }
      } else {
        setAvailableSlot([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAvailableDays = async (doc_id) => {
    try {
      const response = await axios.get(
        `${CONSTANT.url}api/appointments/days/${doc_id}`
      );

      if (Array.isArray(response.data?.data)) {
        setAllAvailableDays(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const daysIndex = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const tileDisabled = ({ date }) => {
    return !allAvailableDays.includes(date.getDay());
  };

  const handleDateChange = (value) => {
    setSelectedTimeSlot("");
    setDate(value);
  };

  const handleTimeSlotChange = (value, data) => {
    setSelectedTimeSlot(value);
    setLabelSlotTime(data?.label);
  };

  const handleLangPrefChange = (value) => {
    setSelectedLangPref(value);
  };

  useEffect(() => {
    console.log("SelletredTImeSlot", labelSlotTime);
  }, [labelSlotTime]);

  // const handleBookAppointment = () => {
  //   let data = doctorApiData;
  //   const selectedIds = cartItems.map((item) => item.itemId);
  //   const submitData = {
  //     labelSlotTime,
  //     selectedTimeSlot,
  //     data,
  //     date: handleDateFormatter(date),
  //     patient_comment: comments,
  //     service_type: doctorType,
  //     promo_code: promo_code,
  //     finalAmount: finalAmount,
  //     item_id: selectedIds,
  //   };
  //   if (doctorType === "home") {
  //     submitData.contact_no = contact_no;
  //     submitData.address = address;
  //   }
  //   if (labelSlotTime) {
  //     localStorage.setItem("book_data", JSON.stringify(submitData));
  //     // window.location.href = "https://eclinicasia.com/book-appointments/";
  //     // window.location.href = "https://stage.eclinicasia.com/book-appointments";
  //     window.location.href = "/book-appointments";
  //     // alert("Book Appointments");

  //     console.log("submitData", submitData);
  //   } else {
  //     alert("Select a Time Slot");
  //   }
  // };

  const handleBookAppointment = () => {
    let data = doctorApiData;
    const selectedIds = cartItems.map((item) => item.itemId);
    const submitData = {
      labelSlotTime,
      selectedTimeSlot,
      data,
      date: handleDateFormatter(date),
      patient_comment: comments,
      service_type: doctorType,
      promo_code: promo_code,
      finalAmount: finalAmount,
      item_id: selectedIds,
      phone: whatsapp_no,
    };
    console.log("checkpassbeofre", whatsapp_no);
    if (doctorType === "home") {
      submitData.contact_no = contact_no;
      submitData.address = address;
    }
    const isLabelSlotTimeInvalid = !labelSlotTime.id || !labelSlotTime.timing;
    if (
      !date ||
      !labelSlotTime ||
      isLabelSlotTimeInvalid ||
      !doctorType ||
      !whatsapp_no
    ) {
      alert("Required fields are empty.");
      return;
    }
    localStorage.setItem("book_data", JSON.stringify(submitData));
    // window.location.href = "/book-appointments";
    window.location.href = "https://stage.eclinicasia.com/book-appointments";

    console.log("submitData", submitData);
  };

  useEffect(() => {
    localStorage.setItem("phoneUpdated", JSON.stringify(whatsapp_no));
  }, [whatsapp_no]);

  const handleButtonClick = () => {
    if (!promo_code) {
      toast.error("Please enter a promo code.");
      resetButton();
      setPromoResponse(null);
      return;
    }

    setIsChecked(false);
    setIsDenied(false);
    setIsVerifying(true);

    const token = localStorage.getItem("token");
    if (!token) {
      const shouldSignIn = window.confirm("Do you need to sign in?");
      alert("Sign In?");
      if (shouldSignIn) {
        setTimeout(() => {
          window.location.href =
            "https://stage.eclinicasia.com/book-appointments";
        }, 1000);
        // setTimeout(() => {
        //   window.location.href = "/book-appointments";
        // }, 1000);
      } else {
        toast.info("You chose not to sign in.");
        setIsVerifying(false);
        setIsChecked(false);
      }
    } else {
      handleVerify(token);
    }
  };

  const handleVerify = async (token) => {
    const payload = {
      promo_code,
      appointment_date: new Date(date).toISOString().split("T")[0],
      spend_value: totalAmount,
    };

    console.log("Payload for promo verification:", payload);

    try {
      const response = await axios.post(
        `${CONSTANT.url}api/domainparmeter/validate-promo-code`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("Response:", response?.data);
      setPromoResponse(response?.data);

      if (
        response.data?.status === "success" ||
        response.data.message.includes("valid")
      ) {
        setIsChecked(true);
        setIsDenied(false);
        console.log("Promo code verified successfully.");

        toast.success(
          response.data.message || "Promo code verified successfully."
        );

        if (response.data.data?.token) {
          localStorage.setItem("token", response.data.data.token);
          console.log("New token saved to localStorage.");
        }
      } else {
        setIsDenied(true);
        setIsChecked(false);
        console.log("Promo code verification failed:", response.data.message);

        toast.error(
          response.data.message || "Promo code is not valid or inactive."
        );
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setPromoResponse(null);

      if (
        error.response?.data?.message === "Promo code is not valid or inactive."
      ) {
        setIsDenied(true);
        setIsChecked(false);
        toast.error("Promo code is not valid or inactive.");
      } else {
        toast.error("No Discount/Promo Applicable.");
      }
    } finally {
      setIsVerifying(false);
      console.log("isVerifying set to:", false);
    }
  };

  const resetButton = () => {
    setIsChecked(false);
    setIsDenied(false);
    setIsVerifying(false);
  };

  const handleItemChange = (e, index, field) => {
    const { value } = e.target;
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };

  const handleAddNewInput = () => {
    if (is_multiple_item === "1") {
      setItems([...items, { item_name: "", item_price: "", item_desc: "" }]);
    }
  };

  const handleRemoveItem = (index) => {
    if (items.length > 1) {
      const newItems = items.filter((_, i) => i !== index);
      setItems(newItems);
    }
  };

  const handleMultipleItemsChange = (e) => {
    const value = e.target.value;
    setMultipleItems(value);
    if (value === "2") {
      setItems([{ item_name: "", item_price: "", item_desc: "" }]);
    }
  };

  useEffect(() => {
    console.log("time Slotsss", window?.location);
  }, [window]);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "white",
        padding: "2rem 1rem",
      }}
    >
      <Helmet>
        <title>
          {`Book your Appointment With - ${
            doctorApiData?.name || idddd ? doctorApiData?.name : ""
          }`}
        </title>

        <meta
          name="description"
          content={`Book your Appointment With - ${
            doctorApiData?.name || idddd ? doctorApiData?.name : ""
          }`}
        />
        <meta
          property="og:title"
          content={`Book your Appointment With - ${
            doctorApiData?.name || idddd ? doctorApiData?.name : ""
          }`}
        />
        <meta
          property="og:description"
          content={`Book your Appointment With - ${
            doctorApiData?.name || idddd ? doctorApiData?.name : ""
          }`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="" />
      </Helmet>
      <div
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
        }}
      >
        {/* Hero Section */}
        <div style={{ margin: "auto" }}>
          {doctorApiData?.image_banner ? (
            <img
              src={CONSTANT.img_url + doctorApiData?.image_banner}
              className="book-isssssmg"
              alt="Doctor"
            />
          ) : (
            <div style={{ height: "100%", marginTop: "40px" }}>
              <ImagedoctorSkeletonss />
            </div>
          )}
        </div>

        {/* Main Content */}
        <div
          style={{
            marginTop: "0rem",
            borderRadius: "0.75rem",
            padding: "1.5rem",
            // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          {/* Doctor Info Grid */}
          <div className="doctor-profile">
            {/* Left Column - About */}
            <div className="doctor-profile__about">
              <h2 className="doctor-profile__name">{doctorApiData?.name}</h2>
              <h3 className="doctor-profile__heading">About</h3>
              <div
                className="doctor-profile__description"
                dangerouslySetInnerHTML={{ __html: doctorApiData?.about }}
              />
            </div>

            {/* Right Column - Qualifications */}
            <div className="doctor-profile__qualifications">
              <div className="doctor-profile__qualifications-list">
                <div className="doctor-profile__qualification-item">
                  <span className="doctor-profile__label">Specialization:</span>
                  <span className="doctor-profile__value">
                    {doctorApiData?.categories
                      ?.map((category) => category.name)
                      .join(", ")}
                  </span>
                </div>

                <div className="doctor-profile__qualification-item">
                  <span className="doctor-profile__label">Experience:</span>
                  <span className="doctor-profile__value">
                    {doctorApiData?.experience} Years
                  </span>
                </div>

                <div className="doctor-profile__qualification-item">
                  <span className="doctor-profile__label">Qualification:</span>
                  <span className="doctor-profile__value">
                    {doctorApiData?.qualification
                      ?.map((q) => q?.name)
                      .join(", ")}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Booking Section */}
          <div>
            <DoctorsCard
              doctorApiData={doctorApiData}
              onSelect={addItemToCart}
            />
          </div>
          <div
            style={{
              display: window.innerWidth <= 1024 ? "block" : "grid",
              gridTemplateColumns:
                window.innerWidth > 1024 ? "7fr 4fr" : undefined, // Only apply grid columns for wider screens
              gap: window.innerWidth > 1024 ? "2rem" : undefined, // Only apply gap for wider screens
              padding: "1.5rem",
              borderRadius: "0.75rem",
              marginTop: "2rem",
            }}
          >
            {/* Left Side - Appointment Booking Section */}
            <div
              style={{
                gridColumn: window.innerWidth <= 1024 ? "1" : "1 / 2",
                gridRow: window.innerWidth <= 1024 ? "1" : "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                width: "100%",
              }}
            >
              <h3
                style={{
                  fontSize: window.innerWidth <= 768 ? "1.1rem" : "1.25rem",
                  fontWeight: "600",
                  color: "#1f2937",
                  marginBottom: "1.5rem",
                  textAlign: "left",
                }}
              >
                Book Appointment
              </h3>

              <div
                style={{
                  gap: "2rem",
                  width: "100%",
                  maxWidth: "100%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <AppointmentCalendar
                    date={date}
                    handleDateChange={handleDateChange}
                    tileDisabled={tileDisabled}
                    style={{
                      width: "100%",
                    }}
                  />
                </div>

                <div style={{ width: "100%" }}>
                  {/* Consultation Fee Section */}
                  {/* <p
                    style={{
                      fontSize: window.innerWidth <= 768 ? "1rem" : "1.125rem",
                      fontWeight: "500",
                      color: "#4b5563",
                      marginBottom: "0.5rem",
                    }}
                  >
                    Consultation Fee
                  </p>
                  <p
                    style={{
                      fontSize: window.innerWidth <= 768 ? "1.25rem" : "1.5rem",
                      fontWeight: "bold",
                      color: "#090446",
                    }}
                  >
                    {doctorApiData?.fee} PKR
                  </p> */}

                  {/* Time Slot Selection */}
                  {Array.isArray(availableSlot) && availableSlot.length ? (
                    <select
                      // value={labelSlotTime?.timing || ""}
                      value={JSON.stringify(labelSlotTime) || ""}
                      onChange={(e) => {
                        const selectedSlot = JSON.parse(e.target.value);
                        setLabelSlotTime(selectedSlot); // selectedSlot now contains both id and timing
                      }}
                      style={{
                        width: "100%",
                        padding: "0.75rem",
                        border: "1px solid #d1d5db",
                        borderRadius: "0.5rem",
                        outline: "none",
                        marginBottom: "1rem",
                      }}
                    >
                      <option value="">Select available time slot</option>
                      {availableSlot.map((slot) => (
                        <option
                          key={slot.id}
                          value={JSON.stringify({
                            id: slot.id,
                            timing: slot.timing,
                          })}
                        >
                          {slot.timing}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <p style={{ color: "#ef4444", textAlign: "center" }}>
                      No slots available
                    </p>
                  )}

                  {/* Service Type Section */}
                  <div
                    style={{
                      marginBottom: "1rem",
                      display: "grid",
                      // gridTemplateColumns:
                      //   window.innerWidth <= 768 ? "1fr" : "repeat(2, 1fr)",
                      gap: "1rem",
                      width: "100%",
                    }}
                  >
                    <Stack spacing={1}>
                      <InputLabel htmlFor="doctor_type">
                        Service Type
                      </InputLabel>
                      <FormControl variant="outlined" fullWidth>
                        <MuiSelect
                          value={doctorType}
                          onChange={(e) => setDoctorType(e.target.value)}
                          displayEmpty
                          input={<OutlinedInput />}
                        >
                          {serviceOptions.length > 0 ? (
                            serviceOptions.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>No services available</MenuItem>
                          )}
                        </MuiSelect>
                      </FormControl>

                      {doctorType === "home" && (
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns:
                              window.innerWidth <= 768
                                ? "1fr"
                                : "repeat(2, 1fr)",
                            gap: "1rem",
                            width: "70%",
                          }}
                        >
                          <ThemeInput
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            placeholder="Enter Address"
                            style={{
                              width: window.innerWidth <= 768 ? "90%" : "100%",
                              borderRadius: "10px",
                              minWidth: "242px",
                              border: "1px solid #ccc",
                              padding: "12px",
                              backgroundColor: "#f9f9f9",
                              fontSize: "16px",
                              fontWeight: "400",
                              color: "#333",
                              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                          <ThemeInput
                            type="number"
                            value={contact_no}
                            onChange={(e) => setNumber(e.target.value)}
                            placeholder="Enter Number"
                            style={{
                              width: window.innerWidth <= 768 ? "90%" : "100%",
                              borderRadius: "10px",
                              border: "1px solid #ccc",
                              padding: "12px",
                              backgroundColor: "#f9f9f9",
                              fontSize: "16px",
                              fontWeight: "400",
                              color: "#333",
                              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                        </div>
                      )}
                    </Stack>
                  </div>

                  {/* Promo Code Section */}
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        window.innerWidth <= 768 ? "1fr" : "2fr 1fr",
                      gap: "1rem",
                      marginTop: "10px",
                    }}
                  >
                    <ThemeInput
                      value={promo_code}
                      onChange={(e) => setPromo_Code(e.target.value)}
                      placeholder="Enter Promo Code"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid #ccc",
                        padding: "12px",
                        backgroundColor: "#f9f9f9",
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#333",
                        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    />

                    <button
                      onClick={handleButtonClick}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: isDenied
                          ? "#d32f2f"
                          : isVerifying
                          ? "#938f8f"
                          : isChecked
                          ? "#4caf50"
                          : "#090446",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        padding: "10px 15px",
                        cursor: "pointer",
                        width: "100%",
                        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      <FaRegCheckCircle style={{ marginRight: "8px" }} />
                      {isVerifying
                        ? "Verifying..."
                        : isDenied
                        ? "Denied"
                        : isChecked
                        ? "Verified"
                        : "Apply Discount/Promo"}
                    </button>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <ThemeInput
                      value={whatsapp_no}
                      type="number"
                      onChange={(e) => setWhatsapp_No(e.target.value)}
                      placeholder="Enter Whatsapp No"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid #ccc",
                        padding: "12px",
                        backgroundColor: "#f9f9f9",
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#333",
                        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    />
                  </div>
                  <textarea
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "0.75rem",
                      border: "1px solid #d1d5db",
                      borderRadius: "0.5rem",
                      height: "6rem",
                      resize: "none",
                      outline: "none",
                      marginTop: "1rem",
                    }}
                    placeholder="Comments"
                  />
                </div>
              </div>
            </div>

            {/* Right Side - Cart Section */}
            <div
              style={{
                gridColumn: window.innerWidth <= 1024 ? "1" : "2 / 3",
                gridRow: window.innerWidth <= 1024 ? "2" : "auto",
                backgroundColor: "#ffffff",
                padding: window.innerWidth <= 768 ? "1rem" : "2rem",
                borderRadius: "1rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                width: "100%",
              }}
            >
              <h3
                style={{
                  fontSize: window.innerWidth <= 768 ? "1.5rem" : "1.75rem",
                  fontWeight: "700",
                  color: "#111827",
                  marginBottom: "2rem",
                  borderBottom: "2px solid #f3f4f6",
                  paddingBottom: "1rem",
                }}
              >
                Services
              </h3>

              <div style={{ flexGrow: 1 }}>
                {/* Promo Response Section */}
                {promoResponse && (
                  <div
                    style={{
                      backgroundColor: "#f8fafc",
                      padding: "1.25rem",
                      borderRadius: "0.75rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "0.5rem",
                        alignItems: "center",
                        color: "#374151",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize:
                            window.innerWidth <= 768 ? "0.8rem" : "0.9rem",
                        }}
                      >
                        Applied Promo
                      </span>
                      <span
                        style={{
                          fontWeight: "600",
                          color: "#059669",
                          backgroundColor: "#ecfdf5",
                          padding: "0.5rem 1rem",
                          borderRadius: "0.5rem",
                          fontSize:
                            window.innerWidth <= 768 ? "0.8rem" : "0.9rem",
                          textAlign: "right",
                        }}
                      >
                        {promoResponse?.promo_name}
                      </span>
                    </div>

                    {/* Discount Display */}
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "0.5rem",
                        alignItems: "center",
                        color: "#374151",
                        marginTop: "0.5rem",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize:
                            window.innerWidth <= 768 ? "0.8rem" : "0.9rem",
                        }}
                      >
                        Discount
                      </span>
                      <span
                        style={{
                          fontWeight: "600",
                          color: "#059669",
                          fontSize:
                            window.innerWidth <= 768 ? "0.8rem" : "0.9rem",
                          textAlign: "right",
                        }}
                      >
                        {promoResponse?.discount_type === "Percentage"
                          ? `${Math.round(
                              parseFloat(promoResponse.discount_value)
                            )}%`
                          : `${Math.round(
                              parseFloat(promoResponse.discount_value)
                            )} PKR`}
                      </span>
                    </div>
                  </div>
                )}

                {/* Cart Items */}
                <div style={{ display: "grid", gap: "1rem" }}>
                  {cartItems.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr auto",
                        gap: "1rem",
                        color: "#374151",
                        padding:
                          window.innerWidth <= 768 ? "0.75rem" : "1rem 1.25rem",
                        backgroundColor: "#f8fafc",
                        borderRadius: "0.75rem",
                        border: "1px solid #e5e7eb",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "500",
                          fontSize:
                            window.innerWidth <= 768 ? "0.9rem" : "1rem",
                        }}
                      >
                        {item.itemName}
                      </span>
                      <span
                        style={{
                          fontWeight: "600",
                          color: "#1f2937",
                          fontSize:
                            window.innerWidth <= 768 ? "0.9rem" : "1rem",
                          textAlign: "right",
                        }}
                      >
                        {item.itemAmount}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              {/* Total Section */}
              <div
                style={{
                  marginTop: "2rem",
                  backgroundColor: "#f8fafc",
                  padding: window.innerWidth <= 768 ? "1rem" : "1.5rem",
                  borderRadius: "0.75rem",
                  border: "1px solid #e5e7eb",
                  display: "grid",
                  gap: "1rem",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "0.5rem",
                    color: "#374151",
                    fontSize: window.innerWidth <= 768 ? "0.9rem" : "1rem",
                  }}
                >
                  <span style={{ fontWeight: "500" }}>Subtotal</span>
                  <span style={{ fontWeight: "600", textAlign: "right" }}>
                    {totalAmount.toLocaleString()} PKR
                  </span>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "0.5rem",
                    paddingTop: "1rem",
                    borderTop: "1px solid #e5e7eb",
                    color: "#111827",
                    fontSize: window.innerWidth <= 768 ? "1rem" : "1.1rem",
                  }}
                >
                  <span style={{ fontWeight: "600" }}>
                    Total After Discount
                  </span>
                  <span
                    style={{
                      fontWeight: "700",
                      color: "#059669",
                      textAlign: "right",
                    }}
                  >
                    {promoResponse?.discounted_amount
                      ? `${promoResponse.discounted_amount.toLocaleString()} PKR`
                      : `${totalAmount.toLocaleString()} PKR`}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              onClick={handleBookAppointment}
              style={{
                width: "70%",
                backgroundColor: "#090446",
                color: "white",
                padding: "1rem",
                borderRadius: "0.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
                border: "none",
                cursor: "pointer",
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              <span>Book Appointment</span>
              <FaLongArrowAltRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
